@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

/* Nav links underline */
.nav-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
  opacity: 1;
  transform: translate3d(-100%, 0, 0);
}

.nav-link {
  overflow: hidden;
  display: block;
  position: relative;
}
.nav-link:hover::after,
.nav-link:focus::after,
.active::after {
  transform: translate3d(0, 0, 0);
}

/* end Nav links underline */

/* Animation */

.float {
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
}

.slide-in-right {
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  display: block;
}

.slide-in-left {
  animation: slide-in-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(1000px);
    opacity: 0;
  }
}

/* Phone animation */
.phone {
  display: inline-block;
  position: relative;
}
.phone::after,
.phone::before {
  content: "";
  box-sizing: border-box;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 2s linear infinite;
}
.phone::after {
  animation-delay: 1s;
}

@keyframes animloader {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

/* Slide down background color animation */

.box {
  background-size: 100% 200%;
  background-image: linear-gradient(to bottom, white 50%, #003e82 50%);
  -webkit-transition: background-position 1s;
  -moz-transition: background-position 1s;
  transition: background-position 1s;
}

.box:hover {
  background-position: 0 -100%;
}

.box:hover h1,
.box:hover p,
.box:hover div {
  transition: 1s;
  color: white;
}

/* Slide Right and Left */

@media screen and (min-width: 1000px) {
  .slide-right {
    animation: slide-right 1s linear infinite;
  }

  .slide-left {
    animation: slide-left 1s linear infinite;
  }
}

@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
}

@keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
}

.slide-in-down {
  visibility: visible;
  transition: visibility 0s;
  animation: slide-in-down 0.5s linear;
}

@keyframes slide-in-down {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
